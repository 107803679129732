import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import footerImg from "../../images/sara/olive.png";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <React.Fragment>
     <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link className="logo-footer" to="#">Saawrah<span className="text-primary">.</span></Link>
                        <ul className="list-unstyled social-icon social mb-0 mt-4 ">
                            <li className="list-inline-item"><a href="https://t.me/saawrah" target="_blank" className="rounded mr-1"><i className="mdi mdi-telegram" title="Telegram"></i></a></li>
                            <li className="list-inline-item ml-2"><a href="https://instagram.com/saawrah" target="_blank" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></a></li>
                            
                        </ul>
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 ">
                        <h4 className="text-light footer-head">Useful Links</h4>
                        <ul className="list-unstyled footer-list mt-4">
                        <li><Link to="/index" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Home</Link></li>
                        <li><Link to="projects" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Projects</Link></li>
                            <li><Link to="resume" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Resume</Link></li>
                            <li><Link to="contact" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Contact</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <img src={footerImg} className="img-fluid rounded" alt="" /> 
                    </div>
                </div>
            </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">Copyright © {new Date().getFullYear()} Saawrah All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                       
                    </div>
                </div>
            </div>
        </footer>
        
      </React.Fragment>
    );
  }
}

export default Footer;
